<template>
    <div class="bg-white pb-4 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <ValidationObserver tag="form" ref="form" v-slot="{ errors }">
            <!-- Name -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['name'] && !errors['name'][0] && touchedFields.name },
                { 'md-error': errors['name'] && errors['name'][0] }
                ]">
                <label for="name">{{ $t('fields.name') }}</label>
                <validation-provider name='name' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="name" name="name" type="text" :placeholder="$t('fields_text.name')" id="name" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <!-- idCLEAR -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['idCLEAR'] && !errors['idCLEAR'][0] && touchedFields.idCLEAR },
                { 'md-error': errors['idCLEAR'] && errors['idCLEAR'][0] }
                ]">
                <label for="idCLEAR">{{ $t('fields.idCLEAR') }}</label>
                <validation-provider name='idCLEAR' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="idCLEAR" name="idCLEAR" type="text" :placeholder="$t('fields_text.idCLEAR')" id="idCLEAR" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <!-- Haulier code -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['haulier_code'] && !errors['haulier_code'][0] && touchedFields.haulier_code },
                { 'md-error': errors['haulier_code'] && errors['haulier_code'][0] }
                ]">
                <label for="haulier_code">{{ $t('fields.haulier_code') }}</label>
                <validation-provider name='haulier_code' rules="required" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="haulier_code" name="haulier_code" type="text" :placeholder="$t('fields_text.haulier_code')" id="haulier_code" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <!-- Email -->
            <div>
                <md-field :class="[
                { 'md-valid': errors['email'] && !errors['email'][0] && touchedFields.email },
                { 'md-error': errors['email'] && errors['email'][0] }
                ]">
                <label for="email">{{ $t('fields.email') }}</label>
                <validation-provider name='email' rules="email" v-slot="{ errors, touched }" mode="aggressive">
                    <md-input v-model="email" name="email" type="text" :placeholder="$t('fields.email')" id="email" :disabled="loading || view"></md-input>
                    <slide-y-down-transition>
                        <md-icon class="error" v-show="errors[0] && touched">close</md-icon>
                    </slide-y-down-transition>
                    <span class="md-error">{{ errors[0] }}</span>
                </validation-provider>
                </md-field>
            </div>

            <div v-if="!view" class="flex justify-end gap-4 mt-4">
                <button v-show="editing || view" @click.prevent="showConfirm = true"
                    class="btn-secondary font-bold py-2 px-4 rounded-xl md:w-40" :disabled="loading">
                    {{ $t("delete") }}
                </button>
                <button @click.prevent="save()" type="submit" class="btn-primary font-bold py-2 px-4 rounded-xl" :disabled="loading || view">
                    {{ $t("save") }}
                </button>
            </div>
        </ValidationObserver>

        <modal v-if="showConfirm" @close="showConfirm = false">
			<h3 slot="header">
				{{ $t("confirm") }}
			</h3>
			<div slot="body">
				<i18n path="confirm_messages.delete_provider" tag="p">
					<template #provider>
						{{ provider.name }}
					</template>
				</i18n>
			</div>
			<div slot="footer" class="flex gap-4">
				<button @click="showConfirm = false"
					class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-xl">
					{{ $t("cancel") }}
				</button>
				<button @click="deleteProvider(provider)"
					class="btn-secondary font-bold py-2 px-4 rounded-xl">
					{{ $t("delete") }}
				</button>
			</div>
		</modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SlideYDownTransition } from "vue2-transitions";
import Modal from '@/components/Modal.vue';
import { createProvider, updateProvider, deleteProvider } from '@/services/providers';
import authMixin from "@/mixins/auth";
export default {
    props: ["provider", "creating", "editing", "view"],
    mixins: [authMixin],
    components: {
        ValidationProvider,
        ValidationObserver,
        SlideYDownTransition,
        Modal,
    },

    data() {
        return {
            loading: false,
            showConfirm: false,
            touchedFields: {
                name: false,
                idCLEAR: false,
                haulier_code: false,
                email: false,
            },
            idProvider: null,
            haulier_code: "",
            name: "",
            idCLEAR: "",
            email: "",
        };
    },

    created: function () {
        this.checkPermissions();
        // Redirect to providers without mode props
        if (this.creating == undefined && this.editing == undefined && this.view == undefined) {
            this.$router.push({ name: this.$i18n.locale + '.providers' });
        }
        if (this.provider) {
            this.idProvider = this.provider.idProvider,
            this.haulier_code = this.provider.haulierCode;
            this.name = this.provider.name;
            this.idCLEAR = this.provider.idCLEAR;
            this.email = this.provider.email;
        }
    },

    methods: {
        async save() {
            this.loading = true;
            var valid = await this.$refs.form.validate();
            if (valid) {
                const providerToSave = {
                    idProvider: this.idProvider,
                    idCLEAR: this.idCLEAR,
                    name: this.name,
                    haulierCode: this.haulier_code,
                    email: this.email,
                };
                if (this.creating) {
                    const response = await createProvider(providerToSave);
                    if (response && response != 409) {
                        this.$noty.success(this.$t('success.provider_saved'));
                        this.clearForm();
                    } else if (response == 409) {
                        this.$noty.error(this.$t('error.idCLEAR_exists'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                } else if (this.editing) {
                    const response = await updateProvider(providerToSave);
                    if (response) {
                        this.$noty.success(this.$t('success.provider_saved'));
                    } else {
                        this.$noty.error(this.$t('error.saved'));
                    }
                }
            }
            this.loading = false;
        },

        clearForm() {
            this.name = "";
            this.idCLEAR = "";
            this.haulier_code = "";
            this.email = "";
            this.touchedFields = {
                name: false,
                idCLEAR: false,
                haulier_code: false,
                email: false,
            };
            this.$refs.form.reset();
        },

        async deleteProvider(provider) {
			this.loading = true;
			await deleteProvider(provider);
			this.showConfirm = false;
			this.$noty.success(this.$t('success.provider_deleted'));
            this.$router.push({ name: this.$i18n.locale + '.providers' });
			this.loading = false;
		},
    },
}
</script>