<template>
    <div class="pt-6 pb-10 md:px-12">
        <!-- Back button -->
        <div class="flex justify-end">
            <a class="back-button" @click="$router.go(-1)">
                <i class="material-icons">arrow_back</i>
                {{ $t("back") }}
            </a>
        </div>
        <div v-if="!loading">
            <!-- Service detail table -->
            <table class="table-services">
                <tbody>
                    <tr>
                        <!-- Checkbox -->
                        <!-- <td>
                            <md-checkbox v-model="services_selected" :value="service"></md-checkbox>
                        </td> -->
                        <!-- Date -->
                        <td class="text-center">
                            <div class="text-black">
                                <div class="uppercase text-xs">
                                    {{
                                        moment(service.date)
                                            .format("ddd")
                                            .slice(0, -1)
                                    }}
                                </div>
                                <div class="text-xl">
                                    {{ moment(service.date).format("DD") }}
                                </div>
                                <div class="uppercase text-xs">
                                    {{
                                        moment(service.date)
                                            .format("MMM")
                                            .slice(0, -1)
                                    }}
                                </div>
                            </div>
                        </td>
                        <!-- Service data -->
                        <td>
                            <div>
                                <div class="flex gap-10">
                                    <span>{{ service.deliveryNote }}</span>
                                    <span>{{ service.vehicle.licensePlate }}</span>
                                </div>
                                <div class="flex gap-12 text-main-color uppercase">
                                    <span class="font-bold">
                                        {{ service.client }}
                                    </span>
                                    <span class="text-sm">
                                        {{ service.contactPhone }}
                                    </span>
                                </div>
                                <div>
                                    {{ service.serviceAddress }}
                                </div>
                                <div>
                                    {{ service.destination }}
                                </div>
                            </div>
                        </td>
                        <!-- Service actions -->
                        <td>
                            <div class="flex flex-col items-end">
                                <div class="uppercase pb-10">
                                    {{ service.driver.name }} {{ service.driver.familyName }}
                                </div>
                            <div>
                                <span class="badge w-28" :class="getClassBadge(service.status)">
                                    {{ $t("service_status." + service.status) }}
                                </span>
                            </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Actions buttons & modals -->
            <div v-if="editing" class="flex justify-end gap-4 pb-4">
                <button v-if="!vehicleAssigned" @click="showModalAssignVehicle()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                    {{ $t("assign_vehicle") }}
                </button>
                <button v-if="!driverAssigned" @click="showModalAssignUser()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                    {{ $t("assign_driver") }}
                </button>
                <button @click="showModalChangeDate()" class="btn-primary font-bold py-2 px-4 rounded-xl">
                    {{ $t("change_date") }}
                </button>
            </div>
            <!-- Edit service modals -->
            <assign-vehicle-modal v-if="showAssignVehicle" @close="closeModalAssignVehicle()" :services="services_selected"/>
            <assign-user-modal v-if="showAssignUser" @close="closeModalAssignUser()" :services="services_selected"/>
            <change-date-modal v-if="showChangeDate" @close="closeModalChangeDate()" :services="services_selected"/>

            <!-- Service Description -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_info.svg')"/>
                <span>{{ $t("description") }}</span>
            </h3>
            <p class="service-description">
                {{ service.description }}
            </p>
            <p v-if="service.comments" class="service-description">
                {{ service.comments }}
            </p>

            <!-- Provider -->
            <div v-if="isAdmin">
                <h3 class="service-detail-title md:text-3xl">
                    <img :src="require('@/assets/icons/icons_menu_provider.svg')"/>
                    <span>{{ $t("provider") }}</span>
                </h3>
                <table class="table-services">
                    <tbody>
                        <tr>
                            <td>
                                {{ service.provider.name }}
                            </td>
                            <td class="text-right">
                                {{ service.provider.idCLEAR }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Driver -->
            <div v-if="driverAssigned">
                <h3 class="service-detail-title md:text-3xl">
                    <img :src="require('@/assets/icons/icons_menu_driver.svg')"/>
                    <span>{{ $t("driver") }}</span>
                </h3>
                <table class="table-services">
                    <tbody>
                        <tr>
                            <td>
                                {{ service.driver.name }} {{ service.driver.familyName }}
                            </td>
                            <td class="text-right">
                                {{ service.driver.email }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Vehicle -->
            <div v-if="vehicleAssigned">
                <h3 class="service-detail-title md:text-3xl">
                    <img :src="require('@/assets/icons/icons_menu_vehicles.svg')"/>
                    <span>{{ $t("vehicle") }}</span>
                </h3>
                <table class="table-services">
                    <tbody>
                        <tr>
                            <td>
                                {{ service.vehicle.licensePlate }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Documents -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_documents.svg')"/>
                <span>{{ $t("documents") }}</span>
            </h3>
            <table class="table-services">
                <tbody>
                    <tr v-for="(document, index) in service.documents" :key="index">
                        <td class="table-row-id uppercase">
                            {{ $t("document_types." + document.documentType) }}
                        </td>
                        <td>
                            {{ document.name }}
                        </td>
                        <td class="text-right">
                            <a target="_blank" :href="document.urlS3">
                                <img :src="require('@/assets/icons/icons_actions_view.svg')" />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Empty Documents Message -->
            <div v-if="!service.documents.length" class="p-4">
                {{ $t("empty_message.documents") }}
            </div>

            <!-- Service Amounts -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_bill.svg')"/>
                <span>{{ $t("service_lines") }}</span>
            </h3>
            <table class="table-services">
                <tbody>
                    <tr v-for="(serviceAmount, index) in service.serviceAmounts" :key="index">
                        <td class="table-row-id">
                            #{{ index + 1 }}
                        </td>
                        <td>
                            {{ serviceAmount.amount }}
                        </td>
                        <td>
                            {{ serviceAmount.description }}
                        </td>
                        <td>
                            {{ serviceAmount.DI }}
                        </td>
                        <td>
                            {{ serviceAmount.idWaste }}
                        </td>
                        <td>
                            {{ serviceAmount.wasteName }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Empty Service Amountss Message -->
            <div v-if="!service.serviceAmounts.length" class="p-4">
                {{ $t("empty_message.service_amounts") }}
            </div>

            <!-- Tickets -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_weight.svg')"/>
                <span>{{ $t("tickets") }}</span>
            </h3>
            <table class="table-services">
                <tbody>
                    <tr v-for="(ticket, index) in service.tickets" :key="index">
                        <td class="table-row-id">
                            {{ ticket.numTicket }}
                        </td>
                        <td>
                            {{ ticket.wasteCode }}
                        </td>
                        <td>
                            {{ ticket.wasteName }}
                        </td>
                        <td>
                            {{ $t("weighing_machine") }}: {{ ticket.bascula_prezero ? $t("yes") : $t("no") }}
                        </td>
                        <td>
                            {{ ticket.amount }} {{ ticket.wasteUnit }}
                        </td>
                        <td class="text-right">
                            <button @click="openImagesModal([ticket.urlPhotoS3])">
                                <img :src="require('@/assets/icons/icons_actions_view.svg')" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Empty Tickets Message -->
            <div v-if="!service.tickets.length" class="p-4">
                {{ $t("empty_message.tickets") }}
            </div>

            <!-- Issues -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_incidents.svg')"/>
                <span>{{ $t("issues") }}</span>
            </h3>
            <table class="table-services">
                <tbody>
                    <tr v-for="(issue, index) in service.issues" :key="index">
                        <td class="table-row-id">
                            {{ issue.date | formatDate }}
                        </td>
                        <td class="w-3/4">
                            {{ issue.text }}
                        </td>
                        <td>
                            <div class="flex gap-2 justify-end">
                                <span>{{ issuePhotos[index].length }}</span>
                                <button @click="openImagesModal(issuePhotos[index])">
                                    <img :src="require('@/assets/icons/icons_actions_view.svg')" />
                                </button>
                                <a :href="generateGoogleMapsUrl(issue.location)" target="_blank">
                                    <img :src="require('@/assets/icons/icons_actions_map.svg')" />
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Empty Issues Message -->
            <div v-if="!service.issues.length" class="p-4">
                {{ $t("empty_message.issues") }}
            </div>

            <!-- Signature -->
            <h3 class="service-detail-title md:text-3xl">
                <img :src="require('@/assets/icons/icons_menu_signature.svg')"/>
                <span>{{ $t("signature") }}</span>
            </h3>
            <div class="signature" v-if="service.signature.signerData">
                <!-- <div class="signature-data">
                    <span>{{ service.signature.date | formatDate }}</span>
                    <span class="text-right">{{  service.signature.signerData}}</span>
                </div> -->
                <p v-if="service.signature.comments">
                    {{ service.signature.comments }}
                </p>
                <img v-else :src="service.signature.urlS3">
                <a
                    v-if="signatureHasLocation"
                    class="btn-location"
                    :href="generateGoogleMapsUrl(service.signature.location)"
                    target="_blank"
                >
                    <img :src="require('@/assets/icons/icons_actions_map.svg')" />
                </a>
            </div>
            <!-- Empty Signature Message -->
            <div v-if="!serviceHasSignature" class="p-4">
                {{ $t("empty_message.signature") }}
            </div>

            <!-- Images Modal -->
            <images-modal v-if="showImagesModal" @close="closeImagesModal()" :images="imagesToShow"/>
        </div>
        <div v-else>
            <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </div>
    </div>
</template>

<script>
import { getServiceByUUID } from "@/services/services";
import { ServiceStatus, googleMaps } from "@/config";
import authMixin from "@/mixins/auth";
import AssignVehicleModal from './AssignVehicleModal.vue';
import AssignUserModal from './AssignUserModal.vue';
import ChangeDateModal from './ChangeDateModal.vue';
import ImagesModal from './ImagesModal.vue';
export default {
    mixins: [authMixin],
    props: ["idService", "editing", "view"],
    components: {
        AssignVehicleModal,
        AssignUserModal,
        ChangeDateModal,
        ImagesModal,
	},
    data() {
        return {
            loading: false,
            service: {},
            showAssignVehicle: false,
            showAssignUser: false,
            showChangeDate: false,
            showImagesModal: false,
            imagesToShow: [],
            services_selected: [],
        };
    },

    computed: {
        vehicleAssigned() {
            if (
                Object.prototype.hasOwnProperty.call(this.service, "vehicle") &&
                Object.keys(this.service.vehicle).length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        driverAssigned() {
            return Object.keys(this.service.driver).length ? true : false;
        },
        issuePhotos() {
            let issues = [];
            for (const issue of this.service.issues) {
                let photosUrl = [];
                for (const photo of issue.photos) {
                    photosUrl.push(photo.urlS3);
                }
                issues.push(photosUrl);
            }
            return issues;
        },
        serviceHasSignature() {
             return Object.keys(this.service.signature).length ? true : false;
        },
        signatureHasLocation() {
            if (this.service.signature.location && this.service.signature.location.latitude && this.service.signature.location.longitude) {
                return true;
            } else {
                return false;
            }
        },
    },

    created: async function () {
        this.loading = true;
        await this.fetchService();
        this.services_selected.push(this.service);
        this.loading = false;
    },

    methods: {
        getClassBadge(status) {
            if (status == ServiceStatus.FINISHED) {
                return "badge-success";
            }
            if (status == ServiceStatus.INPROGRESS) {
                return "badge-primary";
            }
            if (status == ServiceStatus.PENDING) {
                return "badge-secondary";
            }
            if (status == ServiceStatus.FAILED) {
                return "badge-danger";
            }
            if (status == ServiceStatus.PAUSED) {
                return "badge-warning";
            }
            return "";
        },

        showModalAssignVehicle() {
            if (this.services_selected.length >= 1) {
                this.showAssignVehicle = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalAssignVehicle() {
            await this.fetchService();
            this.showAssignVehicle = false;
        },

        showModalAssignUser() {
            if (this.services_selected.length >= 1) {
                this.showAssignUser = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalAssignUser() {
            await this.fetchService();
            this.showAssignUser = false;
        },

        showModalChangeDate() {
            if (this.services_selected.length >= 1) {
                this.showChangeDate = true;
            } else {
                this.$noty.warning(this.$t("warning.no_services"));
            }
        },

        async closeModalChangeDate() {
            await this.fetchService();
            this.showChangeDate = false;
        },

        openImagesModal(images) {
            this.imagesToShow = images;
            this.showImagesModal = true;
        },

        closeImagesModal() {
            this.showImagesModal = false;
        },
        
        generateGoogleMapsUrl(location) {
            return googleMaps.url + location.latitude + "," + location.longitude;
        },

        async fetchService() {
            this.loading = true;
            this.service = await getServiceByUUID(this.idService);
            this.loading = false;
        },
    },
}
</script>

<style scoped>
div.signature {
    width: 300px;
    background: white;
    position: relative;
    margin-top: 1rem;
    border: 1px solid var(--main-color);
}
div.signature > p {
    padding-left: .7rem;
    padding-right: .7rem;
    text-align: justify;
}
div.signature > img:first-child {
    margin-top: 2rem;
    width: 100%;
}
div.signature > a.btn-location {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}
div.signature > div.signature-data {
    width: 100%;
    font-size: .9rem;
    padding: 5px 15px 5px 15px;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
}
</style>